<template>
  <div>
    <h3>Производитель</h3>
  </div>
</template>

<script>
export default {
  name: "BuyersDealer",
}
</script>

<style scoped></style>
